@media screen and (max-width: 768px) {
    #copy{
        font-size: x-large;
        text-align: center;
      
    }

    #design{
        margin: 4px 12px;
    }

    #footerbox{
        width: 235px;
        font-size: 11px;
        
      
    }
}

@media screen and (max-width: 390px) {
    #design{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}