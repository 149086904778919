
 
@media screen and (max-width: 569px) {



    #wholetext{
        padding: 5%;
    
    }
    
} 

 

