
  
  
#skill img:hover {
  filter: drop-shadow(0 0 30px #5c45dc);
 
}


.tools img{
  width: auto;
  height: 35vh;

  padding: 65px;
  
}



.tools{
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  margin: 40px;


}


@media screen and (max-width: 768px) {
  .tools {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  .tools img {
    height: 25vh;
    padding: 45px;
  }
}

@media screen and (max-width: 580px) {
  .tools {
    grid-template-columns: repeat(2, 1fr);
  }

  .tools img {
    height: 20vh;
    padding: 35px;
  }
}

  